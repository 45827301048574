<template>
  <el-aside class="app-aside" :width="isCollapse ? '112px' : '260px'" :class="{ 'is-collapse': isCollapse }">
    <div class="app-aside-logo">
      <a></a>
    </div>

    <div class="asideLogoSmall" v-if="isCollapse">
      <a><img src="@/assets/newStyle/logo_small.png" alt/></a>
    </div>
    <template v-if="!$store.state.app.userInfo.forceBindMfa">
      <div
        v-if="false"
        @click="$window.cbshow()"
        class="chatbotButton"
        style="margin:10px;border:1px solid #fff;border-radius:10px;padding:10px;display:flex;cursor: pointer;"
      >
        <img src="/img/avatar.png" style="width:60px;height:60px;margin-right:20px;" />
        <div style="font-size:25px;line-height: 30px;">
          <p style="color:#fff;">オンラインヘルプ</p>
        </div>
      </div>
      <div class="menuGroup">
        <el-menu router :default-active="activeMenu" :collapse="isCollapse">
          <el-menu-item index="/panel" v-if="$settings.showDashBoard && !isAdmin()" class="icon panel">
            <!-- <svg-icon icon-class="panel"></svg-icon> -->
            <span slot="title">{{ $t("dashboard") }}</span>
          </el-menu-item>
          <!-- 最高管理员交易查询 -->
          <el-menu-item v-if="isAdmin() && menuList.includes('Refund')" index="/systrade" class="icon trade">
            <!-- <svg-icon icon-class="trade"></svg-icon> -->
            <span slot="title">{{ $t("trade") }}</span>
          </el-menu-item>
          <!-- 应当不同OEM需求，如果开启POINT功能，则显示订单管理，支付取引管理，积分取引管理，POINT版入金及集计 -->
          <!-- 取引 -->
          <el-menu-item v-if="menuList.includes('Record')" index="/trade" class="icon trade">
            <!-- <svg-icon icon-class="trade"></svg-icon> -->
            <span slot="title">{{ $t("trade") }}</span>
          </el-menu-item>
          <!-- 订单管理 -->
          <el-menu-item index="/order" v-if="enablePoint() && menuList.includes('Record')" class="icon order">
            <!-- <svg-icon icon-class="trade"></svg-icon> -->
            <span slot="title">{{ $t("orderManage") }}</span>
          </el-menu-item>
          <!-- 支付取引管理 -->
          <!-- <el-menu-item
          index="/paymentManage"
          v-if="process.env.VUE_APP_ENABLEPOINT === 'true' && menuList.includes('Record')"
        >
          <svg-icon icon-class="trade"></svg-icon>
          <span slot="title">{{ $t("paymentManage") }}</span>
        </el-menu-item> -->
          <!-- 积分取引管理 -->
          <el-menu-item index="/tradePoint" v-if="enablePoint() && menuList.includes('Record')" class="icon point">
            <!-- <svg-icon icon-class="trade"></svg-icon> -->
            <span slot="title">{{ $t("tradePoint") }}</span>
          </el-menu-item>
          <!-- 数据罗盘 -->

          <el-menu-item index="/dataCompass" v-if="menuList.includes('DataCompass')" class="icon dataCompass">
            <!-- <svg-icon icon-class="dataCompass"></svg-icon> -->
            <span slot="title">{{ $t("dataScreenRouter") }}</span>
          </el-menu-item>

          <el-submenu index="/dataCompass" v-if="false">
            <template slot="title">
              <svg-icon icon-class="dataCompass"></svg-icon>
              <span slot="title">{{ $t("dataCompassRouter") }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/dataCompass">{{ $t("dataScreenRouter") }}</el-menu-item>
              <el-menu-item index="/tradeDaily">{{ $t("tradeDailyRouter") }}</el-menu-item>
              <el-menu-item index="/tradeMonthly">{{ $t("tradeMonthlyRouter") }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- 入金检索 -->
          <el-menu-item v-if="menuList.includes('Settle')" index="/payment" class="icon receipt">
            <!-- <svg-icon icon-class="receipt"></svg-icon> -->
            <span slot="title">{{ $t("receipt") }}</span>
          </el-menu-item>
          <!-- <el-menu-item v-if="!process.env.VUE_APP_ENABLEPOINT && menuList.includes('Settle')" index="/payment">
          <svg-icon icon-class="receipt"></svg-icon>
          <span slot="title">{{ $t("receipt") }}</span>
        </el-menu-item>
        <el-menu-item v-if="process.env.VUE_APP_ENABLEPOINT && menuList.includes('Settle')" index="/deposit">
          <svg-icon icon-class="receipt"></svg-icon>
          <span slot="title">{{ $t("receipt") }}</span>
        </el-menu-item> -->
          <!-- PSP集计 -->
          <el-menu-item index="/statistics" v-if="menuList.includes('Stat')" class="icon stat">
            <!-- <svg-icon icon-class="statistics"></svg-icon> -->
            <span slot="title">{{ $t("statistic") }}</span>
          </el-menu-item>
          <!-- <el-menu-item index="/statistics" v-if="!enablePoint() && menuList.includes('Stat')">
          <svg-icon icon-class="statistics"></svg-icon>
          <span slot="title">{{ $t("statistic") }}</span>
        </el-menu-item> -->
          <!-- 包含POINT集计 -->
          <!-- <el-menu-item index="/stat" v-if="enablePoint() && menuList.includes('Stat')">
          <svg-icon icon-class="statistics"></svg-icon>
          <span slot="title">{{ $t("statistic") }}</span>
        </el-menu-item> -->

          <!-- message -->
          <el-submenu index="/message" class="icon message">
            <template slot="title">
              <!-- <svg-icon icon-class="notice"></svg-icon> -->
              <span slot="title">{{ $t("notification") }}</span>
            </template>
            <el-menu-item-group>
              <template v-if="isAdmin()">
                <el-menu-item index="/message/sysManagement" v-if="menuList.includes('Message')">{{
                  $t("message.notificationManagement")
                }}</el-menu-item>
              </template>
              <template v-else>
                <el-menu-item index="/message/management" v-if="menuList.includes('Message')">{{
                  $t("message.notificationManagement")
                }}</el-menu-item>
              </template>
              <el-menu-item index="/message">{{ $t("message.notificationList") }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <!-- <el-menu-item
          v-if="
              menuList.includes('User') ||
              menuList.includes('Permission') ||
              menuList.includes('Shop') ||
              menuList.includes('Ent')
          "
          index="/setting/payment"
          class="icon setting"
        >
          <svg-icon icon-class="setting"></svg-icon>
          <span slot="title">{{ $t("setting") }}</span>
        </el-menu-item> -->

          <el-submenu
            index="/setting"
            v-if="
              menuList.includes('User') ||
                menuList.includes('Permission') ||
                menuList.includes('Shop') ||
                menuList.includes('Ent')
            "
            class="icon setting"
          >
            <template slot="title">
              <!-- <svg-icon icon-class="notice"></svg-icon> -->
              <span slot="title">{{ $t("setting") }}</span>
            </template>
            <el-menu-item-group>
              <template v-if="!isAdmin()">
                <el-menu-item index="/setting/tableTent">{{ $t("tableTent") }}</el-menu-item>
                <el-menu-item v-if="includes(this.menuList, 'Shop')" index="/setting/shop">{{
                  $t("storeManagement")
                }}</el-menu-item>

                <el-menu-item
                  v-if="enablePoint() && includes(this.menuList, 'Shop')"
                  index="/setting/pointManagement"
                  >{{ $t("ポイント規則") }}</el-menu-item
                >
                <el-menu-item v-if="menuList.includes('Qr') && user.enableQr" index="/setting/qrmanage">
                  {{ $t("QRManage") }}
                </el-menu-item>
                <el-menu-item v-if="includes(this.menuList, 'User')" index="/setting/user">{{
                  $t("userPageTitle")
                }}</el-menu-item>
                <el-menu-item v-if="includes(this.menuList, 'Permission')" index="/setting/rights">{{
                  $t("userRight")
                }}</el-menu-item>
              </template>
              <template v-else>
                <template v-if="includes(this.menuList, 'Ent')">
                  <el-menu-item index="/setting/adminUser">{{ $t("merchantManagement") }}</el-menu-item>
                  <el-menu-item index="/setting/MFAManage" v-if="includes(this.menuList, 'EntMfaConfig')">{{
                    $t("MFAManagement")
                  }}</el-menu-item>
                  <el-menu-item v-if="user.roleName === 'SuperAdmin'" index="/setting/adminUserRole">{{
                    $t("userRight")
                  }}</el-menu-item>
                  <el-menu-item v-if="user.roleName === 'SuperAdmin'" index="/setting/adminUserManage">{{
                    $t("userPageTitle")
                  }}</el-menu-item>
                </template>
              </template>

              <el-menu-item v-if="false" index="/setting/points">{{ $t("pointsRules") }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <!-- tradetype -->
          <el-menu-item index="/tradetype" v-if="menuList.includes('SystemConfig')" class="icon message">
            <!-- <svg-icon icon-class="panel"></svg-icon> -->
            <span slot="title">{{ $t("tradetypeManage") }}</span>
          </el-menu-item>

          <el-menu-item index="/tenant" v-if="menuList.includes('SystemConfig')" class="icon message">
            <!-- <svg-icon icon-class="panel"></svg-icon> -->
            <span slot="title">{{ $t("tenantManage") }}</span>
          </el-menu-item>

          <el-menu-item index="/feedback" v-if="menuList.includes('SystemConfig')" class="icon message">
            <!-- <svg-icon icon-class="panel"></svg-icon> -->
            <span slot="title">{{ $t("評価") }}</span>
          </el-menu-item>

          <el-menu-item index="/feedback2" v-if="menuList.includes('SystemConfig')" class="icon message">
            <!-- <svg-icon icon-class="panel"></svg-icon> -->
            <span slot="title">{{ $t("フィードバック") }}</span>
          </el-menu-item>

          <el-menu-item index="/subAdmin" v-if="false">
            <svg-icon icon-class="panel"></svg-icon>
            <span slot="title">{{ $t("subAdminManage") }}</span>
          </el-menu-item>

          <el-menu-item index="/help" class="icon help" v-if="false">
            <!-- <svg-icon icon-class="help"></svg-icon> -->
            <span slot="title">{{ $t("supportCenter") }}</span>
          </el-menu-item>

          <el-submenu index="/help" class="icon help">
            <template slot="title">
              <!-- <svg-icon icon-class="notice"></svg-icon> -->
              <span slot="title">{{ $t("supportCenter") }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/help/manual">{{ $t("userGuid") }}</el-menu-item>
              <el-menu-item index="/help/support">{{ $t("customerSupport") }} </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </div>
      <div class="collapse-icon" @click="setCollapse" v-if="false">
        <svg-icon icon-class="collapse"></svg-icon>
      </div>
    </template>
  </el-aside>
</template>
<script>
import { getSessionStorage } from "@/utils/storage";
import { isSuperAdmin, isAdmin, enablePoint } from "utils/index";
import { includes } from "lodash";

export default {
  name: "Sidebar",

  data() {
    return {
      menuList: [],
      routers: {},
      user: {}
    };
  },

  methods: {
    includes,
    enablePoint,
    isAdmin,
    setCollapse() {
      this.$store.commit("app/SET_SIDER_COLLAPSE", !this.isCollapse);
    }
  },

  computed: {
    activeMenu() {
      return this.$route.path;
    },

    isSuperAdmin() {
      return isSuperAdmin();
    },
    isCollapse() {
      return this.$store.state.app.siderCollapse;
    }
  },

  mounted() {
    this.menuList = [];
    //整理本地路由
    let arr = this.$router.options.routes[0].children;
    for (let i in arr) {
      this.routers[arr[i].path] = arr[i];
    }

    const userInfo = JSON.parse(getSessionStorage("userInfo"));
    this.user = userInfo;
    userInfo.sysModulePermisson.forEach(item => {
      this.menuList.push(item.modelName);
    });

    if (document.documentElement.clientWidth < 1000) this.$store.commit("app/SET_SIDER_COLLAPSE", true);

    if (isSuperAdmin()) {
      this.$router.push({ path: "/systrade" });
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
.is-collapse .chatbotButton {
  display: none !important;
}
.app-aside .el-menu.el-menu--collapse {
  transition: 0.2s all ease;
  .el-submenu {
    margin: 0;
    transition: 0.2s all ease;
  }
}
.el-menu--vertical .el-menu-item-group .el-menu-item.is-active {
  background-color: rgba($color: #000000, $alpha: 0.2);
}
.el-menu--vertical .el-menu-item-group .el-menu-item:hover {
  background-color: rgba($color: #000000, $alpha: 0.2);
}
.el-menu--vertical .el-menu-item-group .el-menu-item {
  color: #240d59;
}
.submenu .el-menu {
  .el-menu-item {
    color: #fff;
    padding-left: 0;
    margin: 5px 0;
    margin-left: 15px;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 25px;
    font-size: 18px;
    span {
      margin-left: 14px;
    }
  }

  .el-menu-item.is-active {
    color: #fff;
    // background-color: $sider-active-color;
  }

  // .el-menu-item:focus,
  .el-menu-item:focus {
    background-color: $color-sider-color;
  }
  .el-menu-item:hover {
    color: #fff;
    background-color: $sider-active-color;
  }
}
.el-menu-item-group {
  .el-menu-item {
    color: #fff;
  }
}
.el-submenu__title:focus {
  background-color: $color-sider-color;
}

.app-aside {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: $login-bg-color;

  transition: 0.2s all ease;

  .app-aside-logo {
    //height: 100px;
    margin-left: 0 !important;
    padding-top: 40px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    text-align: center;
    img {
      width: 200px;
    }
  }

  .svg-icon {
    width: 24px;
    height: 24px;
    vertical-align: -6px;
    margin-right: 16px;
  }

  .collapse-icon {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    color: #fff;
    cursor: pointer;

    padding: 10px 0;
  }

  &.is-collapse {
    .app-aside-logo {
      display: none;
    }

    .svg-icon {
      margin-right: 0;
    }

    .collapse-icon {
      .svg-icon {
        transform: rotate(180deg);
      }

      span {
        display: none;
      }
    }

    .el-menu {
      width: 100%;
      .el-menu-item {
        margin-left: 0 !important;
      }
    }
  }

  .el-menu {
    background-color: inherit;
    color: #fff;
    border: 0;
    .el-submenu {
      transition: 0.2s all ease;
      margin: 5px 0;
      margin-left: 15px;
    }

    .el-submenu__title span {
      color: #fff;
      font-size: 18px;
    }
    .el-submenu__title {
      border-top-left-radius: 28px !important;
      border-bottom-left-radius: 25px !important;
    }
    .el-submenu__title:hover {
      border-top-left-radius: 28px !important;
      border-bottom-left-radius: 25px !important;
      background-color: $sider-active-color !important;
    }
    .el-menu-item {
      color: #fff;
      padding-left: 0;
      margin: 5px 0;
      margin-left: 15px;
      border-top-left-radius: 28px;
      border-bottom-left-radius: 25px;
      font-size: 18px;
    }

    .el-menu-item.is-active {
      color: #fff;
      // background-color: $sider-active-color;
    }

    // .el-menu-item:focus,
    .el-menu-item:focus {
      background-color: $color-sider-color;
    }
    .el-menu-item:hover {
      color: #fff;
      background-color: $sider-active-color;
    }
  }
}
</style>
